/*
 * @Author: mikey.zhaopeng 
 * @Date: 2020-07-03 09:44:26 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-07-18 11:23:12
 */

import * as API from '@/api/index'
export default{
    // 销售订单列表
    getOrderList: params => {
        return API.POST('api/saleBill/list', params)
    },
    //获取订单详情
    getOrderDetail:params =>{
        return API.POST('api/saleBill/info',params)
    },
    //获取订单经手人业绩和佣金
    getOrderPerformanceCommission:params =>{
        return API.POST('api/saleBill/employeePerformanceCommission',params)
    },

    //获取项目经手人设置列表
    getProjectSaleHandlerList:params=>{
        return API.POST('api/saleHandler/projectHandler',params)
    },
    //获取产品经手人设置列表
    getproductSaleHandlerList:params=>{
        return API.POST('api/saleHandler/productHandler',params)
    },
    //获取通用次卡销售经手人
    getGenerSaleHandlerList:params=>{
        return API.POST('api/saleHandler/generalCardHandler',params)
    },
    //获取时效卡销售经手人
    getTimeSaleHandlerList:params =>{
        return API.POST('api/saleHandler/timeCardHandler',params)
    },
    //获取储值卡销售经手人
    getSaveSaleHandlerList:params =>{
        return API.POST('api/saleHandler/savingCardHandler',params)
    },
    //获取套餐卡销售经手人
    getPackageSaleHandlerList:params =>{
        return API.POST('api/saleHandler/packageCardHandler',params)
    },
    //计算员工提佣
    saleBillemployeeCommission:params =>{
        return API.POST('api/saleBill/employeeCommission',params)
    },
    //取消订单
    cancelOrder:params =>{
        return API.POST('api/saleBill/cancel',params)
    },
    //修改订单经手人业绩及佣金
    updateEmployeePerformanceCommission:params =>{
        return API.POST('api/saleBill/updateEmployeePerformanceCommission',params)
    },
    //修改订单经备注
    updateRemark:params =>{
        return API.POST('api/saleBill/updateRemark',params)
    }
}